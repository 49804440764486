import React from "react"

// import feature from "../images/feature.png"
import SectionHeader from "./section-header"
import {COLORS} from "../styles/constants"

const ServicesThemes = () => (
  <div style={{ margin: "none auto" }}>
    <div style={{
      padding: "4rem 1rem",
      textAlign: "center",
      background: `${COLORS.lightRed}`
    }}>
      <SectionHeader title="Послуги"/>
      <div style={{ maxWidth: "600px", margin: "0 auto" }}>
        <p>
          Консультація психолога та психотерапія в методі символдрами (кататимно-імагінативної терапії) у Львові та онлайн.
        </p>
      </div>
    </div>
    <div style={{padding: "4rem 1rem", textAlign: "center"}}>
      <div style={{
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
        gridTemplateColumns: "repeat(auto-fit, minmax(320px, 800px))",
      }}>
        <div style={{marginTop: "30px"}}>
          <h2>Теми, з якими працюю:</h2>
          <ul style={{
            color: COLORS.black,
            textAlign: "left",
            listStyle: "none",
          }}>
            <li>Тривожність</li>
            <li>Депресивні стани</li>
            <li>Труднощі в стосунках (з партнером, з дітьми, з батьками)</li>
            <li>Емоційне вигорання в материнстві (батьківстві), на роботі</li>
            <li>Переживання втрат</li>
            <li>Самопізнання та розвиток</li>
            <li>Постановка і досягнення кар’єрних та особистісних цілей</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
);

export default ServicesThemes
