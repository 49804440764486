import React from "react"
import HeroImage from "./image";
import Button from "./button";

const Header = () => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      padding: "4rem 1rem",
      margin: "30px auto 0",
      maxWidth: "800px",
    }}
  >
    <h2 style={{ textAlign: "center" }}>
      <em style={{ fontWeight: 200 }}>
        Клінічний психолог
      </em>
      <br/>
      Наталія Дем‘янюк
    </h2>
    <div style={{
      display: "flex",
      flexDirection: "row-reverse",
      flexWrap: "wrap",
      maxWidth: "550px",
    }}>
      <div style={{
        flex: 1,
        flexBasis: "250px",
        margin: 10,
      }}>
        <div style={{
          clipPath: "inset(2% 5% round 2% 5%)"
        }}>
          <HeroImage />
        </div>
      </div>
      <div style={{
        marginTop: "5px",
        flex: 1,
        flexBasis: "150px",
      }}>
        <p style={{ textAlign: "center",
          fontSize: 20,
          lineHeight: 2
        }}>
          Надаю простір, де можна справитись із проблемами та конфліктами, а також навчитись проживати життя більш
          щасливо та усвідомлено.
        </p>
      </div>
    </div>
    <div style={{
      marginTop: "50px"
    }}>
      <a
        href={"https://docs.google.com/forms/d/e/1FAIpQLSeX0T8dVE1eXnrRbONy1mC36XHQtcdYxeOhwUcKr198x7Cq7g/viewform?usp=sf_link"}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button>
          Записатись на консультацію
        </Button>
      </a>
    </div>
  </div>
);

export default Header
