import React from "react"

import { COLORS } from "../styles/constants"

const SectionHeader = ({ title, description }) => (
  <div>
    <h2>{title}</h2>
    <p style={{ color: COLORS.gray }}>{description}</p>
  </div>
);

export default SectionHeader
