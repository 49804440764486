import React from "react"

import Button from "../components/button"
import SectionHeader from "./section-header"
import '../styles/fa/css/all.css'

const Contacts = () => (
  <div style={{
    padding: "4rem 1rem",
    textAlign: "center",
  }}>
    <SectionHeader title="Консультації"/>
    <div style={{paddingBottom: "2rem"}}>
      <a
        href={"https://docs.google.com/forms/d/e/1FAIpQLSeX0T8dVE1eXnrRbONy1mC36XHQtcdYxeOhwUcKr198x7Cq7g/viewform?usp=sf_link"}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button>
          Записатись на консультацію
        </Button>
      </a>
    </div>

    <h3>
      Контакти:
    </h3>
    <div style={{padding: "0.5rem 0"}}>
      <a href='tel:+380507390753' style={{textDecoration:'none'}}>
        <i className='fa fa-phone' style={{marginRight: '7px'}}></i>+380507390753</a>
    </div>
    <a style={{textDecoration: 'none'}} href='mailto:talia.demianiuk@gmail.com'>
      <i className='far fa-envelope' style={{marginRight: '10px'}}></i>talia.demianiuk@gmail.com</a><br/>
    <div className='contacts' style={{marginTop: '0.6rem'}}>
      <a href='https://fb.com/demianiuk.co.ua' target="_blank" rel="noreferrer noopener">
        <i className='fab fa-2x fa-facebook-f'></i>
      </a>
      <a href='https://www.instagram.com/taliademianiuk/' target="_blank" rel="noreferrer noopener">
        <i className='fab fa-2x fa-instagram'></i>
      </a>
      <a href='https://t.me/taliademianiuk' target="_blank" rel="noreferrer noopener">
        <i className='fab fa-2x fa-telegram-plane'></i>
      </a>
    </div>
  </div>
);

export default Contacts
