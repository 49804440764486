import React from "react"

import Layout from "../components/layout";
import Seo from "../components/seo";
import Hero from "../components/hero";
import ServicesThemes from "../components/services-themes";
import Consult from "../components/consult-contacts";
import AboutMe from "../components/about-me";

const IndexPage = () => (
  <Layout>
    <Seo title="Психолог-консультант Наталія Дем’янюк" />
    <Hero />
    <ServicesThemes />
    <AboutMe />
    <Consult />
  </Layout>
);

export default IndexPage
