import React from "react"

import {COLORS} from "../styles/constants";

import SectionHeader from "./section-header"

const AboutMe = () => (
  <div style={{
    padding: "4rem 2rem",
    textAlign: "center",
    backgroundColor: COLORS.lightRed,
  }}>
    <SectionHeader title="Про мене"/>
    <div style={{maxWidth: '40rem', margin: '0 auto'}}>
      <article style={{textAlign: 'left'}}>
        <p>Клінічний психолог, кандидат у психотерапевти в методі символдрами (кататимно-імагінативної психотерапії).</p>
        <p>Дійсний член ГО «Інститут розвитку символдрами і глибинної психології».</p>

        <p>
          Освіта:
          <ul>
            <li>Український католицький університет (Клінічна психологія з основами психодинамічної терапії)</li>
            <li>Університет інформаційних технологій та менеджменту в м. Жешув, Польща</li>
            <li>Інститут розвитку символдрами та глибинної психології - «Школа індивідуального і групового консультування
              та сімейного порадництва»</li>
          </ul>
        </p>
        <p>
          Участь в семінарах, пройдені тренінги, курси:
          <ul>
            <li>I Міжнародна науково-практична конференція «Символдрама: ремесло і мистецтво»</li>
            <li>«Теоретико-супервізійний семінар з Ульріхом Заксе»</li>
            <li>«Інтегративний підхід в роботі зі сновидінням у психологічному консультуванні»</li>
            <li>«Символдрама в коучингу та оргконсультуванні»</li>
            <li>«Спільний політ. Поглиблене пропрацювання основного ступеню символдрами»</li>
            <li>«Introduction to Psychology» - Yale University</li>
            <li>Курс лекцій з психології - Український католицький університет</li>
          </ul>
        </p>
      </article>
    </div>
  </div>
);

export default AboutMe
